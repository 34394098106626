
import { Options, Vue } from 'vue-class-component'
import InvoiceTablePage from '../components/invoiceTable.vue'
import axios from '@/plugins/axios'
import { ElMessage } from 'element-plus'

interface Result {
  total: number
  list: []
  data: []
}
@Options({
  components: {
    InvoiceTablePage
  }
})
export default class InvoicePage extends Vue {
  listInvoices: any = []
  currentPage = 1
  totalRows = 0
  perPage = 20
  isShowWarning = true
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  selectedCompany = ''
  selectedEmail = ''
  daterange: any = []

  companyData: any = []

  showFilterMenu = false
  isSuperAdmin = false
  isPendingExport = false
  invoicesSelected: any = []

  async created() {
    const mainUserID = this.$store.state.auth.dataLogin.id
    const selectedUserId = this.$store.state.auth?.accountSelected?.owner

    if (!selectedUserId) {
      this.isShowWarning = false
    } else {
      this.isShowWarning = mainUserID == selectedUserId ? false : true
    }
    if (!this.isShowWarning) {
      this.getListInvoices(0)
    }

    this.isSuperAdmin = await this.$store.state.auth.dataLogin.type

    await this.getAll()
  }
  async getListInvoices(page = 0) {
    await this.$store.dispatch('setLoading', true, { root: true })

    let res: Result = {
      total: 0,
      list: [],
      data: []
    }

    if (this.isSuperAdmin) {
      // console.log(
      //   `params: {page: ${page}, limit: ${this.perPage}, name: ${this.selectedCompany}, user_id: ${this.selectedEmail}} `
      // )

      res = await axios.get('admin/invoice', {
        headers: this.headers,
        params: {
          page: page === 0 ? page + 1 : page,
          limit: this.perPage,
          name: this.selectedCompany,
          user_id: this.selectedEmail,
          from: this.daterange && this.daterange[0] && new Date(this.daterange[0]) || null,
          to: this.daterange && this.daterange[0] && this.daterange[1] && new Date(this.daterange[1]) || null
        }
      })

      if (res) {
        // console.log(`res: ${JSON.stringify(res)}`)
        this.totalRows = res.total
        this.listInvoices = res.data
      }
    } else {
      let currentAccount = this.$store.state.auth?.accountSelected
      res = await axios.get('admin/invoice', {
        headers: this.headers,
        params: {
          page: page === 0 ? page + 1 : page,
          limit: this.perPage,
          name: currentAccount.name,
          user_id: currentAccount.owner,
          from: this.daterange && this.daterange[0] && new Date(this.daterange[0]) || null,
          to: this.daterange && this.daterange[0] && new Date(this.daterange[1]) || null
        }
      })

      if (res) {
        // console.log(`res: ${JSON.stringify(res)}`)
        this.totalRows = res.total
        this.listInvoices = res.data
      }
    }

    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.getListInvoices(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.getListInvoices(this.currentPage)
  }
  handleConfirm() {
    this.$router.push({ name: 'DashboardPage' })
  }

  /* Get customer company name/email */
  async getAll() {
    let res = await axios.get('admin/account/get-all', {
      headers: this.headers
    })
    if (res) {
      // console.log(`getAll: ${JSON.stringify(res)}`)
      // console.log(res)
      // console.log(`companyData: ${JSON.stringify(res.data)}`)
      // console.log(
      //   `companyData owner email: ${JSON.stringify(res.data[0].owner.email)}`
      // )
      this.companyData = res.data
    }
  }

  /* Filter */
  toggleFilterMenu() {
    this.showFilterMenu = !this.showFilterMenu
  }

  clearFilterMenu() {
    this.currentPage = 1
    this.selectedCompany = ''
    this.selectedEmail = ''
    this.daterange = []
    this.listInvoices = []
    this.invoicesSelected = []

    this.getListInvoices()
  }

  filterInvoices() {
    this.listInvoices = []
    this.invoicesSelected = []

    this.getListInvoices()
  }

  async exportInvoices() {
    if (this.invoicesSelected.length > 0) {
      let params = []
      for (let invoice of this.invoicesSelected) {
        let invoiceData = {
          'Total': '$' + invoice.total,
          'Issue Date': invoice.releaseDate,
          'ID View': invoice.idView,
          'Status': invoice.status,
          'Package Name': ''
        }
        let connectionsFormat: any[] = []
        invoice.connections.forEach((i: any) => {
          let connection = {
            name: i.connection && i.connection.name || '',
            price: '$' + (i.price || 0),
            quantity: 1
          }
          connectionsFormat.push(connection)
        })
        connectionsFormat.splice(0, 1)
        invoiceData['Package Name'] = JSON.stringify(connectionsFormat)
        params.push(invoiceData)
      }
      await axios
        .post('/user/invoice/export', params, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
    } else {
      let body = {
        query: {
          name: this.selectedCompany,
          user_id: this.selectedEmail,
          from: this.daterange && this.daterange[0] || null,
          to: this.daterange && this.daterange[0] && this.daterange[1] || null
        }
      }
      this.isPendingExport = true
      await axios
        .post('/user/invoice/export', body, {
          headers: this.headers
        })
        .then(function (response) {
          window.location = response?.data?.linkFileExport
          ElMessage.success('Download successfully!')
        })
        .catch(function (error) {
          console.log(error)
          ElMessage.error(error)
        })
        .finally(() => this.isPendingExport = false)
    }
  }

  getMultipleSelection(val:any) {
    this.invoicesSelected = val
  }

  // filterInvoices() {
  //   // Perform filtering based on selectedCompany and selectedEmail
  //   this.listInvoices = this.listInvoices.filter((invoice: any) => {
  //     const companyMatch =
  //       this.selectedCompany === '' ||
  //       this.selectedCompany === invoice.account.name
  //     const emailMatch =
  //       this.selectedEmail === '' ||
  //       this.selectedEmail === invoice.account.owner._id
  //     return companyMatch && emailMatch
  //   })

  //   // Reset the current page to 1 when filtering
  //   this.currentPage = 1
  //   this.totalRows = this.listInvoices.length
  // }
}
