
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: {},
  },
})
export default class InvoiceDetailPage extends Vue {
  item: any;
  subscriptionTimeMap = {
    1: "Minutely",
    60: "Hourly",
    1440: "Daily",
    10080: "Weekly",
    43200: "Monthly",
    525600: "Yearly",
  };
}
